/**
 * Formatter for the website
 */
import { stripHtml } from "@24hbs/utils";
import * as crypto from "crypto-js";

export const isValidDate = (date: Date | string | number | null) => {
    if (date instanceof Date && isFinite(date.getTime())) {
        return true;
    }
    if (date !== null) {
        let dateObj: Date | null = null;
        try {
            if (typeof date === 'string') {
                dateObj = new Date(Date.parse(date));
            }
            else
                if (typeof date === 'number') {
                    dateObj = new Date(date);
                }
                else {
                    return false;
                }
        } catch (e) {
            return false;
        }
        if (dateObj !== null && dateObj instanceof Date && isFinite(dateObj.getTime())) {
            return true;
        }
    }

    return false;

}

export const dateNumberFormat = (dateObject: Date): string => {
    if (!dateObject) {
        dateObject = new Date();
    }
    let format = new Intl.DateTimeFormat("it-IT", {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    return format.format(dateObject);
}

export const dateShortMonthFormat = (date: Date | string): string => {
    let dateObject = new Date();
    if (typeof date === 'string' || date instanceof String) {
        dateObject = new Date();//Date.parse(date));
    }
    else

        if (isValidDate(date)) {
            dateObject = date;
        }

    let format = new Intl.DateTimeFormat("it-IT", {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });

    return format.format(dateObject);
}

export const dateFullMonthFormat = (date: Date | string): string => {
    let dateObject = new Date();
    if (typeof date === 'string' || date instanceof String) {
        dateObject = new Date(date);//Date.parse(date));
    }
    else

        if (isValidDate(date)) {
            dateObject = date;
        }

    let format = new Intl.DateTimeFormat("it-IT", {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    });

    return format.format(dateObject);
}

export const dayOfTheWeek = (dateObject: Date): string => {
    if (!dateObject) {
        dateObject = new Date();
    }

    let format = new Intl.DateTimeFormat("it-IT", {
        weekday: 'short'
    });

    return format.format(dateObject);
}

export const priceFormatter = (priceValue: number): string => {
    return new Intl.NumberFormat('it-IT', {
        style: "currency",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency: "EUR",
    }).format(priceValue);
}

export const cookiesFormatter = (value: string, formatAlias: string): string => {
    let formatted = value + '_' + formatAlias;
    return formatted;
}

export const humanize = (date: Date | string): string => {
    let dateObject = new Date();
    if (typeof date === 'string' || date instanceof String) {
        dateObject = new Date();
    }
    else if (isValidDate(date)) {
        dateObject = date;
    }

    let format = new Intl.DateTimeFormat("it-IT", {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    });

    return format.format(dateObject);
}

export const textEllipsis = (str: string, maxLength: number, side = "end", ellipsis = "..."): string => {
    if (str.length > maxLength) {
        switch (side) {
            case "start":
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}

export const removeHTML = (htmlString: string): string => {
    return htmlString.replace(/<[^>]+>/g, '');
}


export const textEllipsisWithHTML = (
    str: string,
    maxLength: number,
    side = "end",
    ellipsis = "..."
): string => {
    if (str.length > maxLength) {
        const strArr = str.replace(/>/g, '> ').replace(/</g, ' <').split(' ');
        let processCut = '';
        let currentLength = 0;
        const openTags: string[] = []; // Array per tenere traccia dei tag aperti

        for (let i = 0; i < strArr.length; i++) {
            const word = strArr[i];

            if (currentLength <= maxLength - ellipsis.length) {
                if (word.length > 0) {
                    if (word.startsWith('<')) {
                        if (!word.includes('/')) {
                            // Tag di apertura
                            const tagName = word.match(/<([a-zA-Z]+)/);
                            if (tagName) {
                                openTags.push(tagName[1]);
                            }
                        } else {
                            // Tag di chiusura
                            const closingTagName = word.match(/<\/([a-zA-Z]+)/);
                            if (closingTagName) {
                                const index = openTags.lastIndexOf(closingTagName[1]);
                                if (index !== -1) {
                                    openTags.splice(index, 1); // Rimuove il tag corrispondente
                                }
                            }
                        }

                        // Processa il tag di apertura o di chiusura
                        processCut += word;
                    } else {
                        // Testo normale
                        currentLength += word.length;
                        if (currentLength <= maxLength - ellipsis.length) {
                            processCut += word + ' ';
                        }
                    }
                }
            }
        }

        // Chiude tutti i tag aperti
        for (let i = openTags.length - 1; i >= 0; i--) {
            processCut += `</${openTags[i]}>`;
        }

        switch (side) {
            case "start":
                return ellipsis + processCut.trim();
            case "end":
            default:
                return processCut.trim() + ellipsis;
        }
    }
    return str;
};

export const cleanCourseHtml = (htmlString: string): string => {
    return stripHtml(htmlString.replace('<p class=" s', '<p s').replace('<li class=" s', '<li s').replace('<li class=" l', '<li l'));
}


export const isNumeric = (str: string): boolean => {

    // Check if input is string
    if (typeof str != "string")
        return false

    // Use type coercion to parse the _entirety_ of the string
    // (`parseFloat` alone does not do this).
    // Also ensure that the strings whitespaces fail
    return !isNaN(parseInt(str)) &&
        !isNaN(parseFloat(str))
}

export const removeMasterCorso = (str: string, productCode: string = "") => {
    if (productCode === 'YZ23006000' || productCode === 'YZ24004700') {
        return str;
    }

    const splitStr = str.split(' ');
    const result = splitStr.filter(checkWord).join(' ').trim();

    function checkWord(word) {
        return !['master', 'corso', 'corsi'].includes(word.toLowerCase());
    }
    return result;
};

export const removeMasterCorsoLaurea = (str: string, productCode: string = "") => {
    if (productCode === 'YZ23006000' || productCode === 'YZ24004700') {
        return str;
    }

    const splitStr = str.split(' ');
    const result = splitStr.filter(checkWord).join(' ').trim();

    function checkWord(word) {
        return !['master', 'corso', 'corsi', 'laurea'].includes(word.toLowerCase());
    }
    return result;
};

export const appendCompanyName = (): string => {
    return '- 24ORE Business School';
};

export const removeTrailingSlash = (str: string) => {
    if (str) {
        return str.replace(/\/$/, '');
    }
};

/**
 * Function to generate SHA256 hash and return it as a hex-encoded string
 * @param input - The input string to hash
 * @returns - The hex-encoded SHA256 hash of the input
 */
export const sha256Hex = (input: string): string => {
    // Generate the SHA256 hash
    const hash = crypto.SHA256(input);
    // Convert the hash to a hex-encoded string
    return hash.toString(crypto.enc.Hex);
}

export const formatDate = (date: string | undefined, toIso: boolean) => {
    if (date) {
        const birthday = date + '';

        const year = birthday.substr(0, 4);
        const month = birthday.substr(4, 2);
        const day = birthday.substr(6, 2);

        const formattedDate = new Date();
        formattedDate.setFullYear(parseInt(year));
        formattedDate.setMonth(parseInt(month) - 1); // 0 based...
        formattedDate.setDate(parseInt(day));

        return toIso
            ? formattedDate.toISOString().substring(0, 10)
            : formattedDate.toLocaleDateString('it-IT');
    }

    return undefined;
};